.NavbarComponent {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  backdrop-filter: blur(0.5rem);
  z-index: 2;
}

.NavbarComponent-left {
  margin-left: 2rem;
}

.NavbarComponent-right {
  margin-right: 2rem;
}

.NavbarComponent-left a {
  font-family: 'Shippori Mincho', serif;
  padding: 0.5rem;
  border: 4px solid var(--color-medium-blue);
  border-radius: 50%;
  letter-spacing: normal;
}

.NavbarComponent-right a {
  margin-left: 2rem;
}

/*  */
/*  */
/*  */

.nav-link {
  /* align-items: center; */
  background-color: transparent;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  padding: 1rem 1.2rem;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.nav-link:hover {
  box-shadow: var(--color-medium-blue) 0 0 0 4px, transparent 0 0 0 0;
}

.Navbar-x,
.Navbar-hamburger {
  color: var(--color-medium-blue);
  margin-right: 2rem;
}
