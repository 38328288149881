.ContactSection {
  width: 100%;
  /* min-height: calc(100vh - 5rem); */
  margin-top: 20rem;
  margin-bottom: 10rem;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.ContactSection-container {
  width: 100%;
  /* height: 80vh; */
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ContactSection-container-top h2 {
  margin-top: 10rem;
  font-family: 'Shippori Mincho', serif;
  font-size: 10rem;
  color: var(--color-medium-blue);
  opacity: 0.5;
  position: relative;
}

.ContactSection-container-top h2 div {
  width: 12rem;
  height: 12rem;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: -8rem;
  left: 20rem;
  z-index: -1;
}

/* INNER CONTAINER */

.ContactSection-inner-container {
  width: 60%;
  max-width: 1400px;
  margin-top: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.ContactSection-left,
.ContactSection-right {
  width: 50%;
}

/* FORM */

.ContactSection-left form input {
  padding: 1.75rem;
  border-radius: 6px;
  border: 4px solid var(--color-light-blue);
}

.ContactSection-left form input,
.ContactSection-left form textarea,
.ContactSection-left form button {
  width: 80%;
  margin-top: 2rem;
}

.ContactSection-left form textarea {
  height: 200px;
  padding: 1.75rem;
  resize: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  border-radius: 6px;
  border: 4px solid var(--color-light-blue);
}

.ContactSection-left form input:focus,
.ContactSection-left form textarea:focus {
  border: 4px solid var(--color-medium-blue);
}

/* CENTER */

.ContactSection-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContactSection-center div {
  width: 3px;
  height: 300px;
  background: rgba(255, 0, 0, 0.25);
}

.ContactSection-center p {
  color: rgba(255, 0, 0, 0.25);
}

/* RIGHT */

.ContactSection-right {
  /* height: 70%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ContactSection-right-socials {
  margin-top: 4rem;
}

.ContactSection-right-socials a {
  margin: 0 1rem;
  color: var(--color-medium-blue);
}

@media screen and (max-width: 1279px) {
  .ContactSection-inner-container {
    width: 100%;
    max-width: 900px;
  }

  .ContactSection-container-top h2 {
    font-size: 8rem;
  }
}

@media screen and (max-width: 1023px) {
  .ContactSection {
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .ContactSection-container {
    margin-top: 2rem;
  }

  .ContactSection-container-top h2 {
    margin-top: 2rem;
    font-size: 2rem;
  }

  .ContactSection-container-top h2 div {
    width: 3rem;
    height: 3rem;
    top: -1.5rem;
    left: 3rem;
  }

  .ContactSection-inner-container {
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
  }

  .ContactSection-left,
  .ContactSection-right {
    width: 100%;
  }

  .ContactSection-center {
    display: none;
  }

  .ContactSection-left form input,
  .ContactSection-left form textarea,
  .ContactSection-left form button {
    width: 100%;
    margin-top: 1rem;
  }

  .ContactSection-left h3 {
    text-align: center;
  }

  .ContactSection-right {
    margin-top: 2rem;
  }

  .ContactSection-right-socials {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 2879px) {
  .ContactSection-container-top h2 {
    font-size: 14rem;
  }
}
