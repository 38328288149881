.AboutSection {
  width: 100%;
  /* min-height: calc(100vh - 5rem); */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.AboutSection-container {
  width: 100%;
  /* height: 60vh; */
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AboutSection-container-top h2 {
  margin-top: 10rem;
  margin-left: 12rem;
  font-family: 'Shippori Mincho', serif;
  font-size: 10rem;
  color: var(--color-medium-blue);
  opacity: 0.5;
  position: relative;
}

.AboutSection-container-top h2 div {
  width: 12rem;
  height: 12rem;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: -8rem;
  z-index: -1;
}

.AboutSection-container-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AboutSection-container-middle p {
  text-align: justify;
  margin-top: 12rem;
}

.AboutSection-container-middle h3 {
  margin-top: 8rem;
  font-family: 'Shippori Mincho', serif;
  font-size: 4rem;
  color: var(--color-medium-blue);
  opacity: 0.5;
}

.AboutSection-tools {
  margin-top: 8rem;
}

.AboutSection-tools svg {
  margin: 0 1rem;
  color: red;
  opacity: 0.75;
}

@media screen and (max-width: 1023px) {
  .AboutSection {
    padding: 1rem;
  }

  .AboutSection-container {
    /* min-height: 100vh; */
    margin-top: 2rem;
  }

  .AboutSection-container-top h2 {
    margin-top: 2rem;
    font-size: 2rem;
  }

  .AboutSection-container-top h2 div {
    width: 3rem;
    height: 3rem;
    top: -1.4rem;
  }

  .AboutSection-container-middle p {
    margin-top: 2rem;
  }

  .AboutSection-container-middle h3 {
    margin-top: 2rem;
    font-size: 2rem;
  }

  .AboutSection-tools {
    margin-top: 2rem;
    text-align: center;
  }

  .AboutSection-tools svg {
    margin: 0.5rem;
  }
}

@media screen and (min-width: 2879px) {
  .AboutSection-container-top h2 {
    font-size: 14rem;
  }
}
