.MobileMenuComponent {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(0.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.MobileMenuComponent-container {
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.MobileMenuComponent-container a {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-medium-blue);
}

.MobileMenuComponent-container .socials a {
  margin-left: 2rem;
}
