.HeaderSection {
  width: 100%;
  min-height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderSection-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HeaderSection-container-left img {
  width: 100%;
}

.HeaderSection-container-right {
  width: 100%;
  height: 100%;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.HeaderSection-container-right h2 {
  margin-top: 1rem;
  font-family: 'Shippori Mincho', serif;
  font-size: 4rem;
  color: var(--color-medium-blue);
}

.HeaderSection-container-right p {
  margin-top: 2rem;
  font-size: 1.4rem;
}

.HeaderSection-container-right .button {
  margin-top: 2rem;
}

.HeaderSection-container-right .button-2 {
  margin-left: 1rem;
}

@media screen and (max-width: 767px) {
  .HeaderSection {
    padding: 1rem;
    margin-top: 4rem;
  }

  .HeaderSection-container {
    flex-direction: column;
  }

  .HeaderSection-container-right {
    margin-left: 0;
    align-items: center;
  }

  .HeaderSection-container-right p {
    font-size: 1rem;
    text-align: justify;
  }
}
