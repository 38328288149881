.ProjectsSection {
  width: 100%;
  /* min-height: calc(100vh - 5rem); */
  margin-top: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProjectsSection-container {
  width: 100%;
  /* min-height: 60vh; */
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ProjectsSection-container-top h2 {
  margin-top: 10rem;
  margin-right: 12rem;
  font-family: 'Shippori Mincho', serif;
  font-size: 10rem;
  color: var(--color-medium-blue);
  opacity: 0.5;
  position: relative;
  text-align: right;
}

.ProjectsSection-container-top h2 div {
  width: 12rem;
  height: 12rem;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: -8rem;
  right: 0;
  z-index: -1;
}

.title {
  font-size: 1.4rem;
  font-weight: 700;
}

.ProjectsSection-container-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-container-main-right-bottom .tech-container ul {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.project-container-main-right-bottom .tech-container ul li {
  font-size: 1rem;
  margin-left: 1rem;
  letter-spacing: 1px;
  font-weight: 700;
}

.project-container-main-right-bottom .view-container {
  margin-top: 1rem;
}

.project-container-main-right-bottom .view-container a {
  margin-left: 2rem;
}

.project-container-main-right-bottom .view-container a svg {
  margin-right: 1rem;
}

.project-container {
  margin-top: 16rem;
  margin-right: 18rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  text-align: right;
}

.project-container:nth-child(2),
.project-container:nth-child(4) {
  margin-left: 36rem;
}

.project-container-main-left:before {
  content: '';
  width: 100%;
  height: 97.6%;
  background: linear-gradient(245deg, rgba(255, 255, 255, 0.75), transparent);
  position: absolute;
  top: 0;
  left: 0;
}

.project-container-main-right {
  width: 80%;
  position: absolute;
  right: -50%;
}

.project-container-main-right-middle {
  background: var(--color-light-blue);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.project-container-main-right-middle p {
  margin-top: 1rem;
  padding: 1rem;
  letter-spacing: 1px;
}

/* SECONDARY */

.project-container:nth-child(2),
.project-container:nth-child(4) {
  margin-top: 16rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  text-align: left;
}

.project-container-secondary-right:before {
  content: '';
  width: 100%;
  height: 97.6%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.75), transparent);
  position: absolute;
  top: 0;
  left: 0;
}

.project-container-secondary-left {
  width: 80%;
  position: absolute;
  left: -50%;
  z-index: 2;
}

.project-container-secondary-left-middle {
  background: var(--color-light-blue);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

.project-container-secondary-left-bottom .tech-container ul {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
}

.project-container-secondary-left-bottom .tech-container ul li {
  font-size: 1rem;
  margin-right: 1rem;
  letter-spacing: 1px;
  font-weight: 700;
}

.project-container-secondary-left-bottom .view-container {
  margin-top: 1rem;
}

.project-container-secondary-left-bottom .view-container a {
  margin-right: 2rem;
}

.project-container-secondary-left-bottom .view-container a svg {
  margin-right: 1rem;
}

.project-container-secondary-left-middle p {
  margin-top: 1rem;
  padding: 1rem;
  letter-spacing: 1px;
}

@media screen and (max-width: 1023px) {
  .ProjectsSection {
    padding: 1rem;
    margin-top: 2rem;
  }

  .ProjectsSection-container {
    margin-top: 2rem;
  }

  .project-container:nth-child(2),
  .project-container:nth-child(4) {
    margin-left: 0rem;
}

  .ProjectsSection-container-top h2 {
    margin-top: 2rem;
    font-size: 2rem;
  }

  .ProjectsSection-container-top h2 div {
    width: 3rem;
    height: 3rem;
    top: -1.4rem;
  }

  .project-container {
    margin-top: 2rem;
    margin-right: 0;
    flex-direction: column;
    text-align: left;
  }

  .project-container:nth-child(2),
  .project-container:nth-child(4) {
    margin-top: 2rem;
    flex-direction: column-reverse;
    text-align: left;
  }

  .project-container-main-left:before,
  .project-container-secondary-right:before {
    display: none;
  }

  .project-container-main-left img,
  .project-container-secondary-right img {
    width: 100vw;
  }

  .project-container-main-right {
    width: 100%;
    position: relative;
    right: 0;
  }

  .project-container-secondary-left {
    width: 100%;
    position: relative;
    left: 0;
  }

  .project-container-main-right-middle,
  .project-container-secondary-left-middle {
    background: none;
    box-shadow: none;
  }

  .project-container-main-right-bottom .view-container a,
  .project-container-secondary-left-bottom .view-container a {
    margin: 1rem 2rem;
    display: flex;
  }

  .project-container:nth-child(2),
  .project-container:nth-child(4) {
    margin-left: 0;
  }

  .title {
    margin-top: 2rem;
    text-align: center;
  }

  .project-container-main-right-bottom .tech-container ul,
  .project-container-secondary-left-bottom .tech-container ul {
    flex-direction: column;
    align-items: center;
  }

  .project-container-main-right-bottom .tech-container ul li {
    margin-left: 0;
  }

  .project-container-secondary-left-bottom .tech-container ul li {
    margin-right: 0;
  }
}

@media screen and (min-width: 2879px) {
  .ProjectsSection-container-top h2 {
    font-size: 14rem;
  }
}
